import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import react from 'react';
import Sale from '../../effects/sale';
import Tile from "../../components/Tile.js";
import withNeon from "react-neon";
const effect = new Sale();
const SaleTile = withNeon(Tile, effect);
export const frontmatter = {
  title: 'Sale (three.js)',
  author: 'Chris Neale',
  draft: false,
  date: '2016-09-03T23:46:37.121Z',
  tags: ['effect']
}
export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>


<MDXTag name="h3" components={components}>{`Effects / Sale`}</MDXTag>
<MDXTag name="p" components={components}>{`The shader effect puts a rotating sale icon on a component, using three.js to generate and display the model. This effect is not part of the fx library, but it can be downloaded from the React Neon docs repo - `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://github.com/onion2k/react-neon-docs/blob/master/src/effects/sale.js"}}>{`Sale effect`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="h3" components={components}>{`Options`}</MDXTag>
<MDXTag name="p" components={components}>{`Effect has no options.`}</MDXTag>
<MDXTag name="h3" components={components}>{`Example`}</MDXTag>
<SaleTile />
<MDXTag name="h3" components={components}>{`Example Code`}</MDXTag>
<div className="gatsby-highlight" data-language="javascript"><pre className="language-javascript"><code className="language-javascript"><span className="token keyword">import</span> Tile <span className="token keyword">from</span> <span className="token string">"../../components/Tile.js"</span><span className="token punctuation">;</span>{"\n"}<span className="token keyword">import</span> Sale <span className="token keyword">from</span> <span className="token string">'../../effects/sale'</span><span className="token punctuation">;</span>{"\n"}<span className="token keyword">import</span> withNeon <span className="token keyword">from</span> <span className="token string">"react-neon"</span><span className="token punctuation">;</span>{"\n"}<span className="token keyword">const</span> effect <span className="token operator">=</span> <span className="token keyword">new</span> <span className="token class-name">Sale</span><span className="token punctuation">(</span><span className="token punctuation">)</span><span className="token punctuation">;</span>{"\n"}<span className="token keyword">const</span> SaleTile <span className="token operator">=</span> <span className="token function">withNeon</span><span className="token punctuation">(</span>Tile<span className="token punctuation">,</span> effect<span className="token punctuation">)</span><span className="token punctuation">;</span></code></pre></div>
</MDXTag>

export const _frontmatter = {};

  